<template>
    <section>
        <b-row class="mt-4">
            <b-col>
                <b-card title="Soloppgang" 
                    tag="article"
                    class="mb-2">
                    <b-card-text>
                    <i class="fas fa-sun"></i>
                    {{ this.sunrise.toLocaleTimeString([], {hour: '2-digit',minute:'2-digit'})}}
                    <hr>
                    <div v-if="sunrise.getMonth() >=2 && sunrise.getMonth() <10">
                        <!-- Sommer -->
                        <div v-if="sunrise.getHours() < 8">
                            <h2>Heise flagget kl.08:00</h2>
                        </div>
                        <div v-else>
                            <h2>Heis flagget kl.{{ this.sunrise.toLocaleTimeString([], {hour: '2-digit',minute:'2-digit'})}}</h2>
                        </div>
                    </div>
                    <div v-else>
                        <!-- Vinter -->
                        <div v-if="sunrise.getHours() < 9">
                            <h2>Heise flagget kl.09:00</h2>
                        </div>
                        <div v-else>
                            <h2>Heis flagget kl.{{ this.sunrise.toLocaleTimeString([], {hour: '2-digit',minute:'2-digit'})}}</h2>
                        </div>
                    </div>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col>
                <b-card title="Solnedgang" 
                        tag="article"
                        class="mb-2">
                    <b-card-text>
                        <i class="fas fa-moon"></i>
                        {{ this.sunset.toLocaleTimeString([], {hour: '2-digit',minute:'2-digit'})}}
                        <hr>
                        <div v-if="sunset.getHours() > 21">
                            <h2>Ta ned flagget kl.21:00</h2>
                        </div>
                        <div v-else>
                            <h2>Ta ned flagget kl.{{ this.sunset.toLocaleTimeString([], {hour: '2-digit',minute:'2-digit'}) }}</h2>
                        </div>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>

<script>
export default {
  name: "SunTimes",
  props: ["sunset", "sunrise"]
}
</script>

<style>

</style>