<template>
    <b-row>
        <b-col cols="9">
            <input 
                id="byInput1" 
                list="byListe" 
                name="byInput" 
                class="form-control" 
                autocomplete="true"
                v-model="selectedPlace" @change="setPlace" placeholder="skriv inn by..." />
            <datalist id="byListe">
                <option v-bind:value="p.city" v-for="(p,index) in places" :key="index" />
            </datalist>
        </b-col>
        <b-col>
            <b-button variant="danger" @click="clearPlace" :disabled="!selectedPlace">x</b-button>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "SelectCityDropDown",
    data() {
        return {
            places: [{
                    "city": "Oslo",
                    "lat": "59.9111",
                    "lng": "10.7528",
                    "admin_name": "Oslo"
                },
                {
                    "city": "Bergen",
                    "lat": "60.3925",
                    "lng": "5.3233",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Trondheim",
                    "lat": "63.4400",
                    "lng": "10.4000",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Stavanger",
                    "lat": "58.9701",
                    "lng": "5.7333",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "S\u00f8rum",
                    "lat": "59.9135",
                    "lng": "11.3472",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Drammen",
                    "lat": "59.7572",
                    "lng": "10.1907",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Skien",
                    "lat": "59.2096",
                    "lng": "9.6090",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Kristiansand",
                    "lat": "58.1467",
                    "lng": "7.9956",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Sandnes",
                    "lat": "58.8500",
                    "lng": "5.7333",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "T\u00f8nsberg",
                    "lat": "59.2981",
                    "lng": "10.4236",
                    "admin_name": "Vestfold"
                },
                {
                    "city": "Sarpsborg",
                    "lat": "59.2839",
                    "lng": "11.1096",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "\u00c5lesund",
                    "lat": "62.4723",
                    "lng": "6.1549",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Haugesund",
                    "lat": "59.4164",
                    "lng": "5.2700",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Sandefjord",
                    "lat": "59.1312",
                    "lng": "10.2166",
                    "admin_name": "Vestfold"
                },
                {
                    "city": "Bod\u00f8",
                    "lat": "67.2827",
                    "lng": "14.3751",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Troms\u00f8",
                    "lat": "69.6489",
                    "lng": "18.9551",
                    "country": "Norway",
                    "iso2": "NO",
                    "admin_name": "Troms",
                    "capital": "admin",
                    "population": "38980",
                    "population_proper": "38980"
                },
                {
                    "city": "Moss",
                    "lat": "59.4370",
                    "lng": "10.6692",
                    "country": "Norway",
                    "iso2": "NO",
                    "admin_name": "\u00d8stfold",
                    "capital": "minor",
                    "population": "36901",
                    "population_proper": "34492"
                },
                {
                    "city": "Arendal",
                    "lat": "58.4617",
                    "lng": "8.7721",
                    "country": "Norway",
                    "iso2": "NO",
                    "admin_name": "Aust-Agder",
                    "capital": "admin",
                    "population": "30916",
                    "population_proper": "30916"
                },
                {
                    "city": "Hamar",
                    "lat": "60.7945",
                    "lng": "11.0680",
                    "country": "Norway",
                    "iso2": "NO",
                    "admin_name": "Hedmark",
                    "capital": "admin",
                    "population": "27947",
                    "population_proper": "27947"
                },
                {
                    "city": "Larvik",
                    "lat": "59.0532",
                    "lng": "10.0271",
                    "country": "Norway",
                    "iso2": "NO",
                    "admin_name": "Vestfold",
                    "capital": "",
                    "population": "24647",
                    "population_proper": "24647"
                },
                {
                    "city": "Gj\u00f8vik",
                    "lat": "60.8000",
                    "lng": "10.7000",
                    "country": "Norway",
                    "iso2": "NO",
                    "admin_name": "Oppland",
                    "capital": "minor",
                    "population": "22719",
                    "population_proper": "17596"
                },
                {
                    "city": "Harstad",
                    "lat": "68.7989",
                    "lng": "16.5314",
                    "country": "Norway",
                    "iso2": "NO",
                    "admin_name": "Troms",
                    "capital": "minor",
                    "population": "21070",
                    "population_proper": "21070"
                },
                {
                    "city": "Lillehammer",
                    "lat": "61.1161",
                    "lng": "10.4578",
                    "country": "Norway",
                    "iso2": "NO",
                    "admin_name": "Oppland",
                    "capital": "minor",
                    "population": "20580",
                    "population_proper": "20580"
                },
                {
                    "city": "Mo i Rana",
                    "lat": "66.3167",
                    "lng": "14.1667",
                    "country": "Norway",
                    "iso2": "NO",
                    "admin_name": "Nordland",
                    "capital": "minor",
                    "population": "18899",
                    "population_proper": "18899"
                },
                {
                    "city": "Molde",
                    "lat": "62.7483",
                    "lng": "7.1833",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Kristiansund",
                    "lat": "63.1105",
                    "lng": "7.7279",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Kongsvinger",
                    "lat": "60.1912",
                    "lng": "11.9992",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Alta",
                    "lat": "69.9686",
                    "lng": "23.2714",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Elverum",
                    "lat": "60.8833",
                    "lng": "11.5667",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Narvik",
                    "lat": "68.4363",
                    "lng": "17.3983",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Dr\u00f8bak",
                    "lat": "59.6667",
                    "lng": "10.6333",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Grimstad",
                    "lat": "58.3405",
                    "lng": "8.5934",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "Steinkjer",
                    "lat": "64.0147",
                    "lng": "11.4942",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Egersund",
                    "lat": "58.4500",
                    "lng": "6.0067",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "F\u00f8rde",
                    "lat": "61.4519",
                    "lng": "5.8569",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Levanger",
                    "lat": "63.7464",
                    "lng": "11.2996",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Notodden",
                    "lat": "59.5594",
                    "lng": "9.2585",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Flor\u00f8",
                    "lat": "61.5986",
                    "lng": "5.0172",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Namsos",
                    "lat": "64.4670",
                    "lng": "11.4940",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Hammerfest",
                    "lat": "70.6634",
                    "lng": "23.6821",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Lillesand",
                    "lat": "58.2488",
                    "lng": "8.3778",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "\u00c5krehamn",
                    "lat": "59.2603",
                    "lng": "5.1856",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Sandnessj\u00f8en",
                    "lat": "66.0167",
                    "lng": "12.6333",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Vossevangen",
                    "lat": "60.6300",
                    "lng": "6.4410",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Br\u00f8nn\u00f8ysund",
                    "lat": "65.4749",
                    "lng": "12.2120",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Vads\u00f8",
                    "lat": "70.0667",
                    "lng": "29.7500",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Odda",
                    "lat": "60.0689",
                    "lng": "6.5456",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Svolv\u00e6r",
                    "lat": "68.2333",
                    "lng": "14.5667",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Finnsnes",
                    "lat": "69.2406",
                    "lng": "18.0086",
                    "admin_name": "Troms"
                },
                {
                    "city": "R\u00f8ros",
                    "lat": "62.5747",
                    "lng": "11.3842",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Fosnav\u00e5g",
                    "lat": "62.3417",
                    "lng": "5.6339",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Kirkenes",
                    "lat": "69.7250",
                    "lng": "30.0517",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Stokmarknes",
                    "lat": "68.5656",
                    "lng": "14.9058",
                    "admin_name": "Nordland"
                },
                {
                    "city": "M\u00e5l\u00f8y",
                    "lat": "61.9333",
                    "lng": "5.1167",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Rjukan",
                    "lat": "59.8811",
                    "lng": "8.5831",
                    "admin_name": "Telemark"
                },
                {
                    "city": "\u00c5sg\u00e5rdstrand",
                    "lat": "59.3500",
                    "lng": "10.4667",
                    "admin_name": "Vestfold"
                },
                {
                    "city": "Brevik",
                    "lat": "59.0589",
                    "lng": "9.6875",
                    "admin_name": "Telemark"
                },
                {
                    "city": "R\u00f8rvik",
                    "lat": "64.8680",
                    "lng": "11.2053",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Tvedestrand",
                    "lat": "58.6219",
                    "lng": "8.9314",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "Honningsv\u00e5g",
                    "lat": "70.9758",
                    "lng": "25.9831",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "\u00c5ndalsnes",
                    "lat": "62.5672",
                    "lng": "7.6869",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Brekstad",
                    "lat": "63.6870",
                    "lng": "9.6654",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Fagernes",
                    "lat": "60.9850",
                    "lng": "9.2306",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Vard\u00f8",
                    "lat": "70.3703",
                    "lng": "31.1106",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Otta",
                    "lat": "61.7706",
                    "lng": "9.5414",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Kolbotn",
                    "lat": "59.8114",
                    "lng": "10.7983",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Lillestr\u00f8m",
                    "lat": "59.9500",
                    "lng": "11.0833",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Sandvika",
                    "lat": "59.8900",
                    "lng": "10.5264",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Asker",
                    "lat": "59.8333",
                    "lng": "10.4333",
                    "admin_name": "Akershus"
                },
                {
                    "city": "L\u00f8renskog",
                    "lat": "59.9408",
                    "lng": "10.9610",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Randaberg",
                    "lat": "59.0000",
                    "lng": "5.6000",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Sola",
                    "lat": "58.8833",
                    "lng": "5.6000",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Kleppest\u00f8",
                    "lat": "60.4094",
                    "lng": "5.2286",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Fredrikstad",
                    "lat": "59.2167",
                    "lng": "10.9500",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Askim",
                    "lat": "59.5833",
                    "lng": "11.1667",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Porsgrunn",
                    "lat": "59.1405",
                    "lng": "9.6561",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Rygge",
                    "lat": "59.3833",
                    "lng": "10.7167",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Mj\u00f8ndalen",
                    "lat": "59.7500",
                    "lng": "10.0167",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Straume",
                    "lat": "60.3558",
                    "lng": "5.1196",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Ski",
                    "lat": "59.7167",
                    "lng": "10.8333",
                    "admin_name": "Akershus"
                },
                {
                    "city": "R\u00f8yken",
                    "lat": "59.7475",
                    "lng": "10.3936",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Kopervik",
                    "lat": "59.2833",
                    "lng": "5.3000",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Valderhaug",
                    "lat": "62.4985",
                    "lng": "6.1277",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "\u00c5s",
                    "lat": "59.6667",
                    "lng": "10.8000",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Kleppe",
                    "lat": "58.7667",
                    "lng": "5.6667",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Os",
                    "lat": "60.1835",
                    "lng": "5.4666",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Fjerdingby",
                    "lat": "59.9255",
                    "lng": "11.0865",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Jessheim",
                    "lat": "60.1500",
                    "lng": "11.1833",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Langev\u00e5g",
                    "lat": "62.4372",
                    "lng": "6.1889",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Stord",
                    "lat": "59.7738",
                    "lng": "5.4903",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Bryne",
                    "lat": "58.7333",
                    "lng": "5.6500",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Nittedal",
                    "lat": "60.0225",
                    "lng": "10.9239",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Rong",
                    "lat": "60.5133",
                    "lng": "4.9033",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "R\u00f8st",
                    "lat": "67.5167",
                    "lng": "12.1167",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Lier",
                    "lat": "59.7925",
                    "lng": "10.2525",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Ulsteinvik",
                    "lat": "62.3333",
                    "lng": "5.8833",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "S\u00f8rumsand",
                    "lat": "59.9667",
                    "lng": "11.2500",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Frekhaug",
                    "lat": "60.5172",
                    "lng": "5.2461",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Fetsund",
                    "lat": "59.9333",
                    "lng": "11.1667",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Skogsv\u00e5g",
                    "lat": "60.2556",
                    "lng": "5.1017",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Hommelvik",
                    "lat": "63.4087",
                    "lng": "10.7995",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Gjerdrum",
                    "lat": "60.0833",
                    "lng": "11.1000",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Kvits\u00f8y",
                    "lat": "59.0632",
                    "lng": "5.4161",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "S\u00f8gne",
                    "lat": "58.0833",
                    "lng": "7.8167",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Fedje",
                    "lat": "60.7811",
                    "lng": "4.7131",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Mandal",
                    "lat": "58.0333",
                    "lng": "7.4500",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Varhaug",
                    "lat": "58.6167",
                    "lng": "5.6500",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Vikev\u00e5g",
                    "lat": "59.0981",
                    "lng": "5.6981",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "R\u00e5de",
                    "lat": "59.3500",
                    "lng": "10.8500",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Steinshamn",
                    "lat": "62.7833",
                    "lng": "6.4833",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "S\u00e6tre",
                    "lat": "59.6817",
                    "lng": "10.5247",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Hareid",
                    "lat": "62.3667",
                    "lng": "6.0333",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Austrheim",
                    "lat": "60.7644",
                    "lng": "4.9135",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Falkhytta",
                    "lat": "62.8058",
                    "lng": "6.8858",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Eidsvoll",
                    "lat": "60.3167",
                    "lng": "11.2333",
                    "admin_name": "Akershus"
                },
                {
                    "city": "J\u00f8rpeland",
                    "lat": "59.0167",
                    "lng": "6.0500",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Halden",
                    "lat": "59.1500",
                    "lng": "11.3833",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Raufoss",
                    "lat": "60.7167",
                    "lng": "10.6167",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Mysen",
                    "lat": "59.5500",
                    "lng": "11.3333",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Manger",
                    "lat": "60.6417",
                    "lng": "5.0392",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Bremnes",
                    "lat": "59.7953",
                    "lng": "5.1753",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Skj\u00e6rhalden",
                    "lat": "59.0333",
                    "lng": "11.0333",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Langesund",
                    "lat": "59.0000",
                    "lng": "9.7500",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Storeb\u00f8",
                    "lat": "60.0924",
                    "lng": "5.2322",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Spydeberg",
                    "lat": "59.6000",
                    "lng": "11.0833",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Hokksund",
                    "lat": "59.7719",
                    "lng": "9.8839",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Straumen",
                    "lat": "63.8712",
                    "lng": "11.2994",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Skodje",
                    "lat": "62.5167",
                    "lng": "6.7167",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Skiptvet",
                    "lat": "59.4600",
                    "lng": "11.1601",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Vennesla",
                    "lat": "58.2833",
                    "lng": "7.9833",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Ris\u00f8r",
                    "lat": "58.7167",
                    "lng": "9.2333",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "R\u00f8yse",
                    "lat": "60.0769",
                    "lng": "10.1965",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Nannestad",
                    "lat": "60.2124",
                    "lng": "11.0044",
                    "admin_name": "Akershus"
                },
                {
                    "city": "S\u00f8rland",
                    "lat": "67.6667",
                    "lng": "12.7000",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Kl\u00e6bu",
                    "lat": "63.2989",
                    "lng": "10.4805",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Frosta",
                    "lat": "63.5889",
                    "lng": "10.7719",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Hob\u00f8l",
                    "lat": "59.5984",
                    "lng": "10.9420",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Kongsberg",
                    "lat": "59.6500",
                    "lng": "9.6500",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Isdalst\u00f8",
                    "lat": "60.5556",
                    "lng": "5.2692",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Brattv\u00e5g",
                    "lat": "62.6000",
                    "lng": "6.4500",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Krager\u00f8",
                    "lat": "58.8667",
                    "lng": "9.4167",
                    "admin_name": "Telemark"
                },
                {
                    "city": "B\u00f8rsa",
                    "lat": "63.3277",
                    "lng": "10.0683",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Jevnaker",
                    "lat": "60.2500",
                    "lng": "10.4667",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Bruhagen",
                    "lat": "63.0533",
                    "lng": "7.6323",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "\u00c5rnes",
                    "lat": "60.1500",
                    "lng": "11.4667",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Roa",
                    "lat": "60.2833",
                    "lng": "10.6167",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Farsund",
                    "lat": "58.0833",
                    "lng": "6.8000",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Stange",
                    "lat": "60.7167",
                    "lng": "11.1833",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Lena",
                    "lat": "60.6667",
                    "lng": "10.8000",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Utsira",
                    "lat": "59.3000",
                    "lng": "4.9000",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Nodeland",
                    "lat": "58.1500",
                    "lng": "7.8500",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Brumunddal",
                    "lat": "60.8833",
                    "lng": "10.9333",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Lonev\u00e5g",
                    "lat": "60.5333",
                    "lng": "5.5000",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Tr\u00f8gstad",
                    "lat": "59.6459",
                    "lng": "11.2945",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Sistranda",
                    "lat": "63.7277",
                    "lng": "8.8267",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Vikersund",
                    "lat": "59.9833",
                    "lng": "10.0333",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Judaberg",
                    "lat": "59.1833",
                    "lng": "5.8500",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Aksdal",
                    "lat": "59.4259",
                    "lng": "5.4290",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Leknes",
                    "lat": "68.1452",
                    "lng": "13.5993",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Elnesv\u00e5gen",
                    "lat": "62.8538",
                    "lng": "7.1536",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Stj\u00f8rdal",
                    "lat": "63.4703",
                    "lng": "10.9122",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Sykkylven",
                    "lat": "62.3972",
                    "lng": "6.5832",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Melhus",
                    "lat": "63.2858",
                    "lng": "10.2810",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Sveio",
                    "lat": "59.5447",
                    "lng": "5.3492",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "B\u00f8",
                    "lat": "59.4167",
                    "lng": "9.0667",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Eide",
                    "lat": "62.9167",
                    "lng": "7.4333",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "\u00c5lg\u00e5rd",
                    "lat": "58.7667",
                    "lng": "5.8500",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "V\u00e5ler",
                    "lat": "59.4874",
                    "lng": "10.8527",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Midsund",
                    "lat": "62.6833",
                    "lng": "6.6833",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Orkanger",
                    "lat": "63.3122",
                    "lng": "9.8533",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Lyngdal",
                    "lat": "58.1333",
                    "lng": "7.0833",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "L\u00f8ten",
                    "lat": "60.8167",
                    "lng": "11.3167",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Jaren",
                    "lat": "60.3833",
                    "lng": "10.5500",
                    "admin_name": "Oppland"
                },
                {
                    "city": "H\u00f8nefoss",
                    "lat": "60.1667",
                    "lng": "10.3000",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Vestnes",
                    "lat": "62.6333",
                    "lng": "7.0833",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Rakkestad",
                    "lat": "59.4333",
                    "lng": "11.3500",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Bokn",
                    "lat": "59.2236",
                    "lng": "5.4452",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Sogndal",
                    "lat": "61.2315",
                    "lng": "7.1027",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Sortland",
                    "lat": "68.7019",
                    "lng": "15.4239",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Fitjar",
                    "lat": "59.9175",
                    "lng": "5.3228",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Larsnes",
                    "lat": "62.2167",
                    "lng": "5.6000",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "\u00d8len",
                    "lat": "59.6036",
                    "lng": "5.8075",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Bj\u00f8rkelangen",
                    "lat": "59.8833",
                    "lng": "11.5667",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Ulefoss",
                    "lat": "59.2833",
                    "lng": "9.2667",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Skarnes",
                    "lat": "60.2500",
                    "lng": "11.6833",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "\u00d8rskog",
                    "lat": "62.4789",
                    "lng": "6.8116",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Flekkefjord",
                    "lat": "58.2833",
                    "lng": "6.6833",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Volda",
                    "lat": "62.1500",
                    "lng": "6.1000",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Vigeland",
                    "lat": "58.0833",
                    "lng": "7.3000",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Akkerhaugen",
                    "lat": "59.3940",
                    "lng": "9.2471",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Nordfjordeid",
                    "lat": "61.9000",
                    "lng": "6.0000",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Norheimsund",
                    "lat": "60.3667",
                    "lng": "6.1333",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Rosendal",
                    "lat": "59.9833",
                    "lng": "6.0167",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Myre",
                    "lat": "68.9000",
                    "lng": "15.0833",
                    "admin_name": "Nordland"
                },
                {
                    "city": "\u00d8rsta",
                    "lat": "62.2000",
                    "lng": "6.1500",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Botng\u00e5rd",
                    "lat": "63.7688",
                    "lng": "9.8115",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Hermansverk",
                    "lat": "61.1833",
                    "lng": "6.8500",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Siljan",
                    "lat": "59.3000",
                    "lng": "9.7000",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Selje",
                    "lat": "62.0492",
                    "lng": "5.3481",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Hauge",
                    "lat": "58.3500",
                    "lng": "6.2833",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Rissa",
                    "lat": "63.5832",
                    "lng": "9.9591",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Sagstua",
                    "lat": "60.3885",
                    "lng": "11.5376",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Straumsj\u00f8en",
                    "lat": "68.6944",
                    "lng": "14.4718",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Vik",
                    "lat": "65.3000",
                    "lng": "12.1500",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Uggdal",
                    "lat": "60.0089",
                    "lng": "5.5122",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "\u00d8rnes",
                    "lat": "66.8667",
                    "lng": "13.7000",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Skotterud",
                    "lat": "59.9833",
                    "lng": "12.1167",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Tysse",
                    "lat": "60.3708",
                    "lng": "5.7561",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Eikelandsosen",
                    "lat": "60.2394",
                    "lng": "5.7539",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Verdal",
                    "lat": "63.7917",
                    "lng": "11.4846",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Nesna",
                    "lat": "66.2000",
                    "lng": "13.0333",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Fauske",
                    "lat": "67.2627",
                    "lng": "15.3913",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Fisk\u00e5",
                    "lat": "62.0999",
                    "lng": "5.5566",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "S\u00f8rreisa",
                    "lat": "69.1387",
                    "lng": "18.1798",
                    "admin_name": "Troms"
                },
                {
                    "city": "Hurdal",
                    "lat": "60.3962",
                    "lng": "11.0277",
                    "admin_name": "Akershus"
                },
                {
                    "city": "Moi",
                    "lat": "58.4667",
                    "lng": "6.5333",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "\u00d8rje",
                    "lat": "59.4833",
                    "lng": "11.6500",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Askvoll",
                    "lat": "61.3465",
                    "lng": "5.0597",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Reine",
                    "lat": "67.9337",
                    "lng": "13.0891",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Froland",
                    "lat": "58.5333",
                    "lng": "8.6500",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "Hov",
                    "lat": "60.7000",
                    "lng": "10.3500",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Gol",
                    "lat": "60.7000",
                    "lng": "8.9500",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Tingvoll",
                    "lat": "62.9131",
                    "lng": "8.2056",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Hopen",
                    "lat": "63.4667",
                    "lng": "8.0167",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Sauda",
                    "lat": "59.6500",
                    "lng": "6.3333",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Lur\u00f8y",
                    "lat": "66.4170",
                    "lng": "12.8447",
                    "admin_name": "Nordland"
                },
                {
                    "city": "\u00d8yer",
                    "lat": "61.2672",
                    "lng": "10.4244",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Mosj\u00f8en",
                    "lat": "65.8333",
                    "lng": "13.2000",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Solfjellsj\u00f8en",
                    "lat": "66.1167",
                    "lng": "12.4833",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Voss",
                    "lat": "60.6318",
                    "lng": "6.4167",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Gjerstad",
                    "lat": "58.8667",
                    "lng": "9.0000",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "Birkeland",
                    "lat": "58.3333",
                    "lng": "8.2333",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "Naustdal",
                    "lat": "61.5167",
                    "lng": "5.7167",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Ramberg",
                    "lat": "68.0833",
                    "lng": "13.2333",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Dokka",
                    "lat": "60.8333",
                    "lng": "10.0833",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Flisa",
                    "lat": "60.6078",
                    "lng": "12.0521",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Dale",
                    "lat": "61.3667",
                    "lng": "5.4167",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Fillan",
                    "lat": "63.6015",
                    "lng": "8.9657",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Hornindal",
                    "lat": "61.9693",
                    "lng": "6.5225",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Stryn",
                    "lat": "61.9167",
                    "lng": "6.7833",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Evje",
                    "lat": "58.6000",
                    "lng": "7.8500",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "Batnfjords\u00f8ra",
                    "lat": "62.8833",
                    "lng": "7.7000",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Kvinesdal",
                    "lat": "58.3167",
                    "lng": "6.9500",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Kyrks\u00e6ter\u00f8ra",
                    "lat": "63.2906",
                    "lng": "9.0899",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Dale",
                    "lat": "60.5833",
                    "lng": "5.8167",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Meldal",
                    "lat": "63.0461",
                    "lng": "9.7103",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Noresund",
                    "lat": "60.1667",
                    "lng": "9.6167",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Sandane",
                    "lat": "61.7667",
                    "lng": "6.2167",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Skjerv\u00f8y",
                    "lat": "70.0333",
                    "lng": "20.9833",
                    "admin_name": "Troms"
                },
                {
                    "city": "Kirken\u00e6r",
                    "lat": "60.4667",
                    "lng": "12.0500",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Evenskjer",
                    "lat": "68.5833",
                    "lng": "16.5667",
                    "admin_name": "Troms"
                },
                {
                    "city": "Etne",
                    "lat": "59.6667",
                    "lng": "5.9333",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Konsmo",
                    "lat": "58.3000",
                    "lng": "7.4000",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Veg\u00e5rshei",
                    "lat": "58.7667",
                    "lng": "8.8000",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "\u00c5rdalstangen",
                    "lat": "61.2333",
                    "lng": "7.7167",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Marnardal",
                    "lat": "58.2124",
                    "lng": "7.5117",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Leka",
                    "lat": "65.0882",
                    "lng": "11.7129",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Stranda",
                    "lat": "62.3167",
                    "lng": "6.9000",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Skei",
                    "lat": "61.5716",
                    "lng": "6.4790",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "V\u00e5ler",
                    "lat": "60.6667",
                    "lng": "11.8333",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Bogen",
                    "lat": "68.5262",
                    "lng": "16.9923",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Aure",
                    "lat": "63.2667",
                    "lng": "8.5333",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Iveland",
                    "lat": "58.4473",
                    "lng": "7.9163",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "Hyllestad",
                    "lat": "61.1667",
                    "lng": "5.3000",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Hamnvik",
                    "lat": "68.7833",
                    "lng": "17.1833",
                    "admin_name": "Troms"
                },
                {
                    "city": "Vinstra",
                    "lat": "61.6000",
                    "lng": "9.7500",
                    "admin_name": "Oppland"
                },
                {
                    "city": "\u00d8stre Gausdal",
                    "lat": "61.2581",
                    "lng": "10.1539",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Liab\u00f8en",
                    "lat": "63.1167",
                    "lng": "8.3000",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Ranemsletta",
                    "lat": "64.4936",
                    "lng": "11.9483",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Slidre",
                    "lat": "61.0883",
                    "lng": "8.9808",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Sande",
                    "lat": "61.3333",
                    "lng": "5.8000",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Lensvik",
                    "lat": "63.5110",
                    "lng": "9.8089",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Lampeland",
                    "lat": "59.8342",
                    "lng": "9.5759",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Fossby",
                    "lat": "59.2167",
                    "lng": "11.7000",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "H\u00f8yanger",
                    "lat": "61.2167",
                    "lng": "6.0833",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Svelgen",
                    "lat": "61.7667",
                    "lng": "5.3000",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Sj\u00f8vegan",
                    "lat": "68.8733",
                    "lng": "17.8644",
                    "admin_name": "Troms"
                },
                {
                    "city": "Kolvereid",
                    "lat": "64.8623",
                    "lng": "11.6041",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Jondal",
                    "lat": "60.2833",
                    "lng": "6.2833",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Vikes\u00e5",
                    "lat": "58.6333",
                    "lng": "6.1000",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Seljord",
                    "lat": "59.4833",
                    "lng": "8.6167",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Borkenes",
                    "lat": "68.7833",
                    "lng": "16.1833",
                    "admin_name": "Troms"
                },
                {
                    "city": "Leland",
                    "lat": "66.0667",
                    "lng": "12.9500",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Surnadal",
                    "lat": "62.9754",
                    "lng": "8.7246",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Malm",
                    "lat": "64.0719",
                    "lng": "11.2187",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "S\u00f8r-Fron",
                    "lat": "61.5667",
                    "lng": "9.9333",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Sunndals\u00f8ra",
                    "lat": "62.6667",
                    "lng": "8.5500",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "L\u00f8dingen",
                    "lat": "68.4162",
                    "lng": "15.9953",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Nesbyen",
                    "lat": "60.5667",
                    "lng": "9.1000",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "\u00c5l",
                    "lat": "60.6333",
                    "lng": "8.5667",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Granvin",
                    "lat": "60.5500",
                    "lng": "6.7167",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Prestfoss",
                    "lat": "60.0333",
                    "lng": "9.6333",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Br\u00f8stadbotn",
                    "lat": "69.0794",
                    "lng": "17.7014",
                    "admin_name": "Troms"
                },
                {
                    "city": "Hardbakke",
                    "lat": "61.0833",
                    "lng": "4.8500",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Drangedal",
                    "lat": "59.1000",
                    "lng": "9.0667",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Hol",
                    "lat": "68.5333",
                    "lng": "16.4000",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Kviteseid",
                    "lat": "59.4000",
                    "lng": "8.5000",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Stordal",
                    "lat": "62.3820",
                    "lng": "6.9861",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Lyngseidet",
                    "lat": "69.5833",
                    "lng": "20.2167",
                    "admin_name": "Troms"
                },
                {
                    "city": "Eivinvik",
                    "lat": "60.9833",
                    "lng": "5.0667",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Storsteinnes",
                    "lat": "69.2407",
                    "lng": "19.2344",
                    "admin_name": "Troms"
                },
                {
                    "city": "Ringebu",
                    "lat": "61.5167",
                    "lng": "10.1667",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Tingvatn",
                    "lat": "58.4000",
                    "lng": "7.2333",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Gryllefjord",
                    "lat": "69.3667",
                    "lng": "17.0167",
                    "admin_name": "Troms"
                },
                {
                    "city": "\u00c5rnes",
                    "lat": "63.9611",
                    "lng": "10.2274",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Selbu",
                    "lat": "63.2170",
                    "lng": "11.0400",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Heggenes",
                    "lat": "61.1500",
                    "lng": "9.0833",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Vik\u00f8yri",
                    "lat": "61.0833",
                    "lng": "6.5833",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Rena",
                    "lat": "61.1333",
                    "lng": "11.3667",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "\u00c5rstein",
                    "lat": "68.6890",
                    "lng": "17.5449",
                    "admin_name": "Troms"
                },
                {
                    "city": "Tennevoll",
                    "lat": "68.7466",
                    "lng": "17.8038",
                    "admin_name": "Troms"
                },
                {
                    "city": "St\u00f8ren",
                    "lat": "63.0372",
                    "lng": "10.2856",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Korgen",
                    "lat": "66.0833",
                    "lng": "13.8333",
                    "country": "Norway",
                    "iso2": "NO",
                    "admin_name": "Nordland",
                    "capital": "minor",
                    "population": "",
                    "population_proper": ""
                },
                {
                    "city": "Rollag",
                    "lat": "60.0227",
                    "lng": "9.2695",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Rindal",
                    "lat": "63.0500",
                    "lng": "9.2167",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Hemsedal",
                    "lat": "60.8667",
                    "lng": "8.5667",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Masfjordnes",
                    "lat": "60.8000",
                    "lng": "5.3000",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Oppdal",
                    "lat": "62.5976",
                    "lng": "9.6984",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "V\u00e5g\u00e5",
                    "lat": "61.8688",
                    "lng": "9.1233",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Tynset",
                    "lat": "62.2729",
                    "lng": "10.7804",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Ballangen",
                    "lat": "68.3405",
                    "lng": "16.8260",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Skaland",
                    "lat": "69.4500",
                    "lng": "17.3000",
                    "admin_name": "Troms"
                },
                {
                    "city": "Eidsv\u00e5g",
                    "lat": "62.7819",
                    "lng": "8.0711",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Bruflat",
                    "lat": "60.8833",
                    "lng": "9.6167",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Inndyr",
                    "lat": "67.0333",
                    "lng": "14.0333",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Bagn",
                    "lat": "60.8167",
                    "lng": "9.5667",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Hjelmeland",
                    "lat": "59.2333",
                    "lng": "6.1833",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Vangsvik",
                    "lat": "69.1667",
                    "lng": "17.7333",
                    "admin_name": "Troms"
                },
                {
                    "city": "Alvdal",
                    "lat": "62.1167",
                    "lng": "10.6500",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Berk\u00e5k",
                    "lat": "62.8286",
                    "lng": "10.0101",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Roan",
                    "lat": "64.1729",
                    "lng": "10.2250",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Steinsdalen",
                    "lat": "64.2429",
                    "lng": "10.6926",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Dalen",
                    "lat": "59.4500",
                    "lng": "8.0000",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Kinsarvik",
                    "lat": "60.3758",
                    "lng": "6.7259",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Leinesfjord",
                    "lat": "67.7765",
                    "lng": "15.0147",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Hol",
                    "lat": "60.6200",
                    "lng": "8.2972",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Sand",
                    "lat": "59.4823",
                    "lng": "6.2788",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Lauvsnes",
                    "lat": "64.5007",
                    "lng": "10.8959",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Hansnes",
                    "lat": "69.9667",
                    "lng": "19.6000",
                    "admin_name": "Troms"
                },
                {
                    "city": "Gaupne",
                    "lat": "61.4048",
                    "lng": "7.2942",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Rognan",
                    "lat": "67.1000",
                    "lng": "15.3833",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Namdalseid",
                    "lat": "64.2229",
                    "lng": "11.2210",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Olderdalen",
                    "lat": "69.6167",
                    "lng": "20.5333",
                    "admin_name": "Troms"
                },
                {
                    "city": "Trysil",
                    "lat": "61.3167",
                    "lng": "12.2667",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Mer\u00e5ker",
                    "lat": "63.4207",
                    "lng": "11.7552",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Sauland",
                    "lat": "59.6203",
                    "lng": "8.9278",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Dovre",
                    "lat": "61.9833",
                    "lng": "9.2500",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Balestrand",
                    "lat": "61.2000",
                    "lng": "6.5333",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Krokstad\u00f8ra",
                    "lat": "63.3998",
                    "lng": "9.4987",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Grong",
                    "lat": "64.4646",
                    "lng": "12.3160",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Os",
                    "lat": "62.4958",
                    "lng": "11.2243",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Moen",
                    "lat": "69.1333",
                    "lng": "18.6167",
                    "admin_name": "Troms"
                },
                {
                    "city": "V\u00e5gaholmen",
                    "lat": "66.7091",
                    "lng": "13.2693",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Valldal",
                    "lat": "62.3333",
                    "lng": "7.3500",
                    "admin_name": "M\u00f8re og Romsdal"
                },
                {
                    "city": "Oppeid",
                    "lat": "68.0856",
                    "lng": "15.6117",
                    "admin_name": "Nordland"
                },
                {
                    "city": "L\u00e6rdals\u00f8yri",
                    "lat": "61.1000",
                    "lng": "7.4833",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Treungen",
                    "lat": "59.0333",
                    "lng": "8.5500",
                    "admin_name": "Telemark"
                },
                {
                    "city": "H\u00f8ylandet",
                    "lat": "64.6272",
                    "lng": "12.2977",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "\u00c5mli",
                    "lat": "58.7833",
                    "lng": "8.5000",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "\u00c5len",
                    "lat": "62.8433",
                    "lng": "11.2994",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Forsand",
                    "lat": "58.9024",
                    "lng": "6.0964",
                    "admin_name": "Rogaland"
                },
                {
                    "city": "Ulvik",
                    "lat": "60.5667",
                    "lng": "6.9000",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Breivikbotn",
                    "lat": "70.5879",
                    "lng": "22.2848",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Bardu",
                    "lat": "68.8656",
                    "lng": "18.3473",
                    "admin_name": "Troms"
                },
                {
                    "city": "Tolga",
                    "lat": "62.4089",
                    "lng": "10.9986",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "\u00d8ksfjord",
                    "lat": "70.2392",
                    "lng": "22.3714",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Storslett",
                    "lat": "69.7667",
                    "lng": "21.0167",
                    "admin_name": "Troms"
                },
                {
                    "city": "B\u00e5tsfjord",
                    "lat": "70.6308",
                    "lng": "29.6972",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Fl\u00e5",
                    "lat": "60.4167",
                    "lng": "9.4667",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Kj\u00f8psvik",
                    "lat": "68.0966",
                    "lng": "16.3752",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Tonstad",
                    "lat": "58.6667",
                    "lng": "6.7167",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Folldal",
                    "lat": "62.1358",
                    "lng": "9.9906",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Straumen",
                    "lat": "67.3481",
                    "lng": "15.5972",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Lom",
                    "lat": "61.8333",
                    "lng": "8.5500",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Terr\u00e5k",
                    "lat": "65.0833",
                    "lng": "12.4167",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Koppang",
                    "lat": "61.5667",
                    "lng": "11.0667",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "\u00c5seral",
                    "lat": "58.6167",
                    "lng": "7.4167",
                    "admin_name": "Vest-Agder"
                },
                {
                    "city": "Vinje",
                    "lat": "59.6201",
                    "lng": "7.8413",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Fyresdal",
                    "lat": "59.1885",
                    "lng": "8.0897",
                    "admin_name": "Telemark"
                },
                {
                    "city": "Oteren",
                    "lat": "69.2554",
                    "lng": "19.8816",
                    "admin_name": "Troms"
                },
                {
                    "city": "Skj\u00e5k",
                    "lat": "61.8811",
                    "lng": "8.3693",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Dun",
                    "lat": "64.6569",
                    "lng": "11.2646",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Vang",
                    "lat": "61.1245",
                    "lng": "8.5740",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Aurlandsvangen",
                    "lat": "60.9063",
                    "lng": "7.1864",
                    "admin_name": "Sogn og Fjordane"
                },
                {
                    "city": "Hav\u00f8ysund",
                    "lat": "70.9988",
                    "lng": "24.6603",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "R\u00f8dberg",
                    "lat": "60.2711",
                    "lng": "8.9537",
                    "admin_name": "Buskerud"
                },
                {
                    "city": "Bygland",
                    "lat": "58.8500",
                    "lng": "7.8500",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "Valle",
                    "lat": "59.2000",
                    "lng": "7.5333",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "Lesja",
                    "lat": "62.1212",
                    "lng": "8.8457",
                    "admin_name": "Oppland"
                },
                {
                    "city": "Vevelstad",
                    "lat": "65.7167",
                    "lng": "12.4667",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Moldjord",
                    "lat": "67.0067",
                    "lng": "14.5764",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Mo",
                    "lat": "60.8167",
                    "lng": "5.8000",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Berlev\u00e5g",
                    "lat": "70.8602",
                    "lng": "29.0853",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Sn\u00e5sa",
                    "lat": "64.2456",
                    "lng": "12.3818",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Lakselv",
                    "lat": "70.0436",
                    "lng": "24.9758",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Bykle",
                    "lat": "59.3500",
                    "lng": "7.3333",
                    "admin_name": "Aust-Agder"
                },
                {
                    "city": "Eidfjord",
                    "lat": "60.4667",
                    "lng": "7.0833",
                    "admin_name": "Hordaland"
                },
                {
                    "city": "Tana Bru",
                    "lat": "70.1977",
                    "lng": "28.1849",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Trofors",
                    "lat": "65.5333",
                    "lng": "13.4063",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Engerdal",
                    "lat": "61.7584",
                    "lng": "11.9594",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Tydal",
                    "lat": "63.0554",
                    "lng": "11.5658",
                    "admin_name": "S\u00f8r-Tr\u00f8ndelag"
                },
                {
                    "city": "Burfjord",
                    "lat": "69.9333",
                    "lng": "22.0500",
                    "admin_name": "Troms"
                },
                {
                    "city": "Bergset",
                    "lat": "61.8897",
                    "lng": "11.0773",
                    "admin_name": "Hedmark"
                },
                {
                    "city": "Hattfjelldal",
                    "lat": "65.5963",
                    "lng": "13.9978",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Varangerbotn",
                    "lat": "70.1762",
                    "lng": "28.5541",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Mehamn",
                    "lat": "71.0373",
                    "lng": "27.8507",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Namsskogan",
                    "lat": "64.9286",
                    "lng": "13.1595",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Karasjok",
                    "lat": "69.4711",
                    "lng": "25.5019",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Kvalsund",
                    "lat": "70.5027",
                    "lng": "23.9800",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Kj\u00f8llefjord",
                    "lat": "70.9457",
                    "lng": "27.3463",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "Kautokeino",
                    "lat": "69.0124",
                    "lng": "23.0408",
                    "admin_name": "Finnmark"
                },
                {
                    "city": "R\u00f8yrvik",
                    "lat": "64.8832",
                    "lng": "13.5635",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "Tr\u00e6na",
                    "lat": "66.5017",
                    "lng": "12.0963",
                    "admin_name": "Nordland"
                },
                {
                    "city": "Nordli",
                    "lat": "64.4659",
                    "lng": "13.5907",
                    "admin_name": "Nord-Tr\u00f8ndelag"
                },
                {
                    "city": "R\u00f8mskog",
                    "lat": "59.7127",
                    "lng": "11.8416",
                    "admin_name": "\u00d8stfold"
                },
                {
                    "city": "Nesoddtangen",
                    "lat": "59.8667",
                    "lng": "10.6500",
                    "admin_name": "Oslo"
                },
                {
                    "city": "Andenes",
                    "lat": "69.3203",
                    "lng": "16.1428",
                    "admin_name": "Nordland"
                }
            ],
            selectedPlace: null
        }
    },
    methods: {
        setPlace() {
            if (this.places.length > 0) {
                this.$emit("update-isLoading", true);
                var place = this.places.filter(x => x.city === this.selectedPlace)[0];
                if (place) {
                    localStorage.selectedPlace = this.selectedPlace;
                    localStorage.selectedPlaceLat = place.lat;
                    localStorage.selectedPlaceLng = place.lng;
                    this.$emit("update-Place",place);
                }
                this.$emit("update-isLoading", false);
            }
        },
        clearPlace() {
            this.selectedPlace = null;
            this.$emit("clear");
        }
    },
    mounted() {
        this.selectedPlace = localStorage.selectedPlace;
    }
}
</script>

<style>

</style>