<template>
    <b-container class="mt-4">
        <section id="finn" class="center">
            <h1>Finn din by</h1>
            <hr>
            <p>Vennligst velg en by for å se når du skal heise og fire flagget</p>
            <select-city-drop-down 
                @update-Place="handleCitySelected" 
                @clear="handleClear"
                @update-isLoading="handlingLoadingSelection">
            </select-city-drop-down>
        </section>
        <section class="center">
            <div v-if="isLoading" class="text-center">
                <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-if="!isLoading && sunrise && sunset">
                <SunTimes :sunrise="sunrise" :sunset="sunset" />
            </div>
            
        </section>
        <section id="oversikt" class="center mt-4">
            
            <h1>Flaggtider i Norge!</h1>
            <hr>
            <p>Du kan flagge alle dager i Norge og hovedregelen er at flagget skal heises ved soloppgang og fires ved
                solnedgang.</p>
            <p>Siden det er et varierende antall timer med dagslys i Norge har man delt opp flaggtider i 2 kategorier,
                se tabell under.</p>
            <b-table striped hover :items="items" class="m4"></b-table>
            <p><small>*<em>Det er egne regler for Nord-Norge (Nordland, Troms, Finnmark, Svalbard og Jan Mayen). Om vinteren skal flagget ikke heises før klokken 10, og ikke fires senere enn klokken 15.</em></small></p>
        </section>
    </b-container>
</template>

<script>
import SelectCityDropDown from '@/components/SelectCityDropDown';
import SunTimes from '@/components/SunTimes';
import axios from "axios";

export default {
    name: "Home",
    components: {
        SelectCityDropDown,
        SunTimes
    },
    data() {
        return {
            isLoading: false,
            selectedPlace: null,
            sunrise: null,
            sunset: null,
            items: [
                {Beskrivelse: "Sommerhalvåret", Periode: "Mars til oktober", Opp: "Ikke før kl.08:00", Ned: "Solnedgang eller maks 21:00"},
                {Beskrivelse: "Vinterhalvåret", Periode: "November til februar", Opp: "Ikke før kl.09:00*", Ned: "Solnedgang eller maks 21:00"}
            ]
        }
    },
    methods: {
        handleCitySelected(place) {
            this.loadSunData(place);
        },
        handlingLoadingSelection(isLoading) {
            if (isLoading) {
                this.isLoading = true;
            } else {
                this.isLoading = false;
            }
        },
        handleClear(){
            this.sunrise = null;
            this.sunset = null;
        },
        loadSunData(place){
            var date = new Date().toISOString().slice(0, 10);
            console.log("Loading sun data");
            console.log("Date:");
            console.log(date);
            var self = this;
            axios.get(
                    `https://api.flaggtider.no/Tider?lat=${place.lat}&lng=${place.lng}&date=${date}`
                    )
                .then(r => {
                    localStorage.sunset = JSON.stringify(r.data.sunset);
                    localStorage.sunrise = JSON.stringify(r.data.sunrise);
                    localStorage.cachedDate = date;
                    self.sunrise= new Date(r.data.sunrise);
                    self.sunset= new Date(r.data.sunset);
                });
        }
    },
    mounted() {
        if(localStorage.cachedDate) {
            var cachedDate = new Date(localStorage.cachedDate).toISOString().slice(0, 10);
            var today = new Date().toISOString().slice(0, 10);
            console.log("cached date:");
            console.log(cachedDate);
            console.log(today);
            if(cachedDate !== today) {
                localStorage.removeItem('date');
                localStorage.removeItem('sunset');
                localStorage.removeItem('sunset');
                if(localStorage.selectedPlace) {
                    this.loadSunData(
                        {
                            lat: localStorage.selectedPlaceLat, 
                            lng: localStorage.selectedPlaceLng
                        });
                }
            }
        } 
        if(localStorage.sunset) {
            this.sunset = new Date(JSON.parse(localStorage.sunset));
        }
        if(localStorage.sunrise) {
            this.sunrise = new Date(JSON.parse(localStorage.sunrise));
        }
    }
}
</script>

<style>

</style>